import axios from "axios";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OnlinePaymentProps, Status } from "../types/onlinePayment";

interface OnlinePaymentState {
  onlinePayment: OnlinePaymentProps;
}

const initialState: OnlinePaymentState = {
  onlinePayment: {
    status: Status.Succeeded,
    paymentMethods: [],
    paymentMethod: { brand: "", type: "" },
    pspReference: "",
  },
};

export const getPaymentStatus = createAsyncThunk(
  "onlinePayment/getPaymentStatus",
  async ({ uuid }: { uuid: string }, thunkAPI) => {
    const accessToken = thunkAPI.getState().generalState.general.accessToken;

    if (!accessToken) {
      return null;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_PAY_BY_LINK_SERVICE_URL}/payment-links/${uuid}/status?access_token=${accessToken}`
    );

    return response.data;
  }
);

export const getPaymentMethods = createAsyncThunk(
  "onlinePayment/getPaymentMethods",
  async (
    {
      storeId,
      amount,
      currencyCode,
      currentLanguage,
    }: {
      storeId: string;
      amount: number;
      currencyCode: string;
      currentLanguage: string;
    },
    thunkAPI
  ) => {
    const accessToken = thunkAPI.getState().generalState.general.accessToken;

    if (!accessToken) {
      return null;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_ONLINE_PAYMENTS_SERVICE_URL}/adyen/payment-methods?access_token=${accessToken}`,
      {
        params: {
          store: storeId,
          country: "DE", // TODO: set correct country
          locale: currentLanguage,
          currency: currencyCode,
          amount: amount,
        },
      }
    );
    return response.data;
  }
);

// TODO: any -> set type
export const createPayment = createAsyncThunk(
  "onlinePayment/createPayment",
  async (
    {
      data,
      storeId,
      amount,
      currencyCode,
      uuid,
      returnUrl,
      tokenize,
      metadata,
      application,
    }: {
      data: any;
      storeId: string;
      amount: number;
      currencyCode: string;
      currentLanguage: string;
      uuid: string;
      returnUrl: string;
      tokenize: boolean;
      metadata: Array<{ key: string; value: string }>;
      application: {
        name: string;
        version: string;
        device: { name: string; version: string };
      };
    },
    thunkAPI
  ) => {
    const accessToken = thunkAPI.getState().generalState.general.accessToken;

    if (!accessToken) {
      return null;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_ONLINE_PAYMENTS_SERVICE_URL}/adyen/payment?access_token=${accessToken}`,
      {
        store: storeId,
        reference_code: uuid,
        currency: currencyCode,
        amount: amount,
        payment_method: data,
        return_url: window.location.href, // Url to redirect after payment from external payment provider
        tokenize: tokenize,
        metadata: metadata,
        application: application
      }
    );
    return response.data;
  }
);

export const onlinePaymentSlice = createSlice({
  initialState,
  name: "onlinePayment",
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getPaymentMethods.pending, (state) => {
        state.onlinePayment.status = Status.Loading; // TODO: reset payment methods?
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.onlinePayment.status = Status.Succeeded;
        state.onlinePayment.paymentMethods = action.payload?.payment_methods;
      })
      .addCase(getPaymentMethods.rejected, (state) => {
        state.onlinePayment.status = Status.Failed; // TODO: reset payment methods?
      })
      .addCase(createPayment.pending, (state) => {
        state.onlinePayment.status = Status.Paying; // TODO: reset data ?
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.onlinePayment.status = Status.Succeeded;

        state.onlinePayment.paymentMethod = action.payload?.payment_method;
        state.onlinePayment.creditCard = action.payload?.credit_card;
        state.onlinePayment.pspReference = action.payload?.psp_reference;
      })
      .addCase(createPayment.rejected, (state) => {
        state.onlinePayment.status = Status.Failed; // TODO: reset data ?
      });
  },
});

export default onlinePaymentSlice.reducer;
