import { useEffect } from "react";
import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";

import { getProvider, setProviderAssets } from "./core/providers/helpers";

import ApplicationWrapper from "application-wrapper";
import DefaultLayout from "./views/layouts/default";

import AuthorizedPaymentScreen from "./views/screens/payment/authorized";
import RefusedPaymentScreen from "./views/screens/payment/refused";
import CheckoutScreen from "./views/screens/checkout";
import BankInformationScreen from "./views/screens/checkout/bank-transfer/bank-information";
import RequestBankDataScreen from "./views/screens/checkout/bank-transfer/request-bank-data";
import DemoScreen from "./views/screens/demo";
import InvalidLinkScreen from "./views/screens/invalid-link";
import PaymentScreen from "./views/screens/payment";
import LoadingScreen from "./views/screens/loading";
import TermsScreen from "./views/screens/terms";
import PrivacyScreen from "./views/screens/privacy";

import "./i18n/configuration";

const App = () => {
  const Provider = getProvider();

  useEffect(() => {
    // Set provider specific icons (favicon etc.)
    setProviderAssets(Provider?.key ? Provider.key : "default");
  }, [Provider]);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {Provider ? (
          <>
            <Route path="/:uuid" element={<ApplicationWrapper />}>
              <Route element={<DefaultLayout provider={Provider} />}>
                <Route
                  path=""
                  element={<PaymentScreen provider={Provider} />}
                />
                <Route
                  path="/:uuid/:method"
                  element={<CheckoutScreen provider={Provider} />}
                />
                <Route
                  path="/:uuid/bank-transfer/bank-information"
                  element={<BankInformationScreen provider={Provider} />}
                />
                <Route
                  path="/:uuid/bank-transfer/request-bank-data"
                  element={<RequestBankDataScreen provider={Provider} />}
                />
              </Route>
              <Route
                path="/:uuid/status/authorized"
                element={<AuthorizedPaymentScreen provider={Provider} />}
              />
              <Route
                path="/:uuid/status/refused"
                element={<RefusedPaymentScreen provider={Provider} />}
              />
              <Route path="/:uuid/terms" element={<TermsScreen />} />
              <Route path="/:uuid/privacy" element={<PrivacyScreen />} />
            </Route>
            <Route path="/demo" element={<DemoScreen provider={Provider} />} />
            <Route
              path="/invalid"
              element={<InvalidLinkScreen provider={Provider} />}
            />
            <Route
              path="*"
              element={<InvalidLinkScreen provider={Provider} />}
            />
          </>
        ) : (
          <Route path="*" element={<InvalidLinkScreen />} />
        )}
      </Routes>
    </Suspense>
  );
};

export default App;
