import axios from "axios";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { DataProps, Status } from "../types/data";

interface DataState {
  data: DataProps;
}

const initialState: DataState = {
  data: {
    status: Status.Idle,
    storeId: null,
    language: null,
    currency: { code: "", symbol: "" },
    amount: 0.0,
    store: null,
    paymentItems: [],
  },
};

export const getData = createAsyncThunk(
  "data/getData",
  async ({ uuid }: { uuid: string }, thunkAPI) => {
    const accessToken = thunkAPI.getState().generalState.general.accessToken;

    if (!accessToken) {
      return null;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_PAY_BY_LINK_SERVICE_URL}/payment-links/${uuid}/details?access_token=${accessToken}`
    );

    return response.data;
  }
);

export const dataSlice = createSlice({
  initialState,
  name: "data",
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getData.pending, (state) => {
        state.data.status = Status.Loading;
      })
      .addCase(getData.fulfilled, (state, action) => {
        // @formatter:off
        state.data.status = Status.Succeeded;
        state.data.storeId = action.payload?.store_id;
        state.data.currency = action.payload?.currency;
        state.data.language = action.payload?.language;
        state.data.paymentMethods = action.payload?.payment_methods;
        state.data.returnUrl = action.payload?.return_url;
        state.data.referenceCode = action.payload?.reference_code;
        state.data.amount = action.payload?.amount;
        state.data.provider = action.payload?.provider;
        state.data.store = action.payload?.store;
        state.data.paymentItems = action.payload?.payment_items;
        state.data.title = action.payload?.title;
        state.data.description = action.payload?.description;
        state.data.tokenize = action.payload?.tokenize;
        state.data.messages = action.payload?.messages;
        state.data.metadata = action.payload?.metadata;
        state.data.application = action.payload?.application;
        // @formatter:on
      })
      .addCase(getData.rejected, (state) => {
        state.data.status = Status.Failed;
      });
  },
});

export default dataSlice.reducer;
