import axios from "axios";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { PaymentLinkProps, Status } from "../types/paymentLink";

interface PaymentLinkState {
  paymentLink: PaymentLinkProps;
}

const initialState: PaymentLinkState = {
  paymentLink: {
    status: Status.Idle,
    url: null,
    storeId: null,
    entityId: null,
    reference: null,
    currency: null,
    amount: null,
  },
};

export const createPaymentLink = createAsyncThunk(
  "paymentLink/createPaymentLink",
  async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_PAY_BY_LINK_SERVICE_URL}/payment-links/`,
      {
        store: "ST32CN2223227L5LH52CC6BVN",
        amount: 120.0,
        currency: "EUR",
        external_id: "2024",
        language: "de",
        return_url: "https://www.reheg.com",
        pre_authorize: true,
        tokenize: false,
        payment_methods: ["credit_card", "bank_transfer"],
        payment_items: [
          {
            title: {
              en: "Test",
            },
            description: {
              en: "Test",
            },
            amount: 120.0,
            image_base64:
              "iVBORw0KGgoAAAANSUhEUgAAAAgAAAAIAQMAAAD+wSzIAAAABlBMVEX///+/v7+jQ3Y5AAAADklEQVQI12P4AIX8EAgALgAD/aNpbtEAAAAASUVORK5CYII=",
            period: {
              start: "2024-04-28T00:00:00+02:00",
              end: "2024-04-29T00:00:00+02:00",
            },
          },
        ],
        metadata: [
          { key: "test1", value: "value_1" },
          { key: "test2", value: "value_2" },
        ],
        application: {
          name: "ASA HOTEL",
          version: "22.20.1",
          device: {
            name: "Windows",
            version: "11",
          },
        },
      },
      {
        auth: {
          username: process.env.REACT_APP_DEMO_USER_USERNAME as string,
          password: process.env.REACT_APP_DEMO_USER_PASSWORD as string,
        },
      }
    );
    return response.data;
  }
);

export const paymentLinkSlice = createSlice({
  initialState,
  name: "paymentLink",
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createPaymentLink.pending, (state) => {
        state.paymentLink.status = Status.Loading;
      })
      .addCase(createPaymentLink.fulfilled, (state, action) => {
        // @formatter:off
        state.paymentLink.status = Status.Succeeded;
        state.paymentLink.url = action.payload.url;
        // @formatter:on
      })
      .addCase(createPaymentLink.rejected, (state) => {
        state.paymentLink.status = Status.Failed;
      });
  },
});

export default paymentLinkSlice.reducer;
